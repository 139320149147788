/* eslint-disable react/no-danger */
import React, { useCallback, useEffect, useState } from 'react';
import Head from 'next/head';
import Router from 'next/router';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import wrapper from 'redux-api/store';
import theme from 'theme';
import Snackbar from 'components/redux-snackbar';
import DownloadBanner from 'components/download-banner';
import 'antd/dist/antd.css';
import 'moment/locale/it';
import 'moment/locale/de';
import 'moment/locale/pt';
import '../css/style.css';
import selectAuth from 'redux-api/reselect/auth';

const queryClient = new QueryClient();
function App({ Component, pageProps }) {
  const {
    signInUserSession: { accessToken },
  } = useSelector(selectAuth);
  const { languageCode } = useSelector((state) => state.config);
  const handleChangeRouter = useCallback(() => {
    window.scrollTo(0, 0);
  }, []);
  const [token, setToken] = useState(null);

  Router.events.on('routeChangeComplete', handleChangeRouter);

  useEffect(() => {
    if (!languageCode) return;
    if (window && window.Beacon) {
      window.Beacon('destroy');
      window.Beacon(
        'init',
        languageCode === 'it'
          ? '73f18650-b57c-4df6-bb59-11cb87363a20'
          : languageCode === 'de'
          ? '809fed9c-d94a-4075-aa44-2fac477072e3'
          : '4284fbd6-de97-460d-acec-a084e1a1bda3',
      );
    }
  }, [languageCode]);

  useEffect(() => {
    if (!accessToken) return;
    async function fetchToken() {
      const baseUrl = process.env.NODE_ENV === 'development' ? `${process.env.API_URL}/public` : process.env.API_URL;
      const res = await fetch(`${baseUrl}/chainlit-token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': accessToken,
        },
      });
      const data = await res.json();
      setToken(data.token);
    }

    fetchToken();
  }, [accessToken]);


  useEffect(() => {
    if (process.env.APP_ENV === 'prod' && !token) return;

    const script = document.createElement('script');
    script.src = 'https://staging-bookmind.streetlib.com/copilot/index.js';
    script.async = true;

    script.onload = () => {
      if (window.mountChainlitWidget) {
        console.log('COPILOT');
        window.mountChainlitWidget({
          chainlitServer: 'https://staging-bookmind.streetlib.com',
          accessToken: token,
          button: {
            style: {
              bgcolor: '#098EA0',
              bgcolorHover: '#0A6F7D',
              color: '#FFF',
            },
          },
        });
      } else {
        console.log('mountChainlitWidget not found');
      }
    };

    document.body.appendChild(script);
  }, [token]);

  return (
    <ThemeProvider theme={theme}>
      <Head>
        <meta name="robots" content="noindex, nofollow" />
        <meta
          content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=3"
          name="viewport"
        />
        <link href="/favicon/favicon-32.png" rel="icon" type="image/x-icon" />
        <link href="/favicon/favicon-256.png" rel="apple-touch-icon" />
        <link rel="manifest" href="/manifest.json" />

        {process.env.APP_ENV === 'prod' && (
          <>
            <script
              async
              src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GA_TRACKING_ID}`}
            />
            <script
              async
              dangerouslySetInnerHTML={{
                __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${process.env.GA_TRACKING_ID}');
          `,
              }}
            />
          </>
        )}

        {process.env.APP_ENV === 'prod' && (
          <>
            <script
              async
              dangerouslySetInnerHTML={{
                __html: `
                !function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});`,
              }}
            />
          </>
        )}
      </Head>

      <QueryClientProvider client={queryClient}>
        <Component {...pageProps} />
      </QueryClientProvider>
      <Snackbar />
      <DownloadBanner />
    </ThemeProvider>
  );
}

const getInitialProps = async ({ Component, ctx }) => {
  let pageProps = {};

  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx);
  }

  return { pageProps };
};

App.getInitialProps = getInitialProps;

export default wrapper.withRedux(App);
